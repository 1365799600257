import { useNavigate } from 'react-router-dom';

export const RootPage = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="bg-red-300 p-2">root</div>
      <button
        className="border border-red-500"
        onClick={async () => {
          navigate('/guestbook');
        }}
      >
        버튼클릭_텍스트 추적
      </button>
    </div>
  );
};
