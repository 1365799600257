import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { RootPage } from './pages/Root';
import { GuestBook } from './pages/GuestBook';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<RootPage></RootPage>}></Route>
        <Route
          path="/1"
          element={<div className="bg-red-300 p-2">1</div>}
        ></Route>

        <Route
          path="/2"
          element={<div className="bg-red-300 p-2">2</div>}
        ></Route>

        <Route path="/guestbook" element={<GuestBook></GuestBook>}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
