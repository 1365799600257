import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

export const GuestBook = () => {
  return (
    <div>
      <Helmet>
        <title>방명록</title>
      </Helmet>
      <div className="bg-red-300 p-2">guestBook</div>
    </div>
  );
};
